import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState } from 'react';

export default function SelectSmall({ callback, handleGiocatori, giocatori, bool }) {
  const [players, setPlayers] = useState('');

  const handleChange = (event) => {
    callback(event.target.value);
    handleGiocatori(event.target.value)
  };
  
  return (

    <>

      {bool && <FormControl sx={{ width:'100%'}} size="medium">
        <InputLabel id="demo-select-medium-label">Giocatori</InputLabel>
        <Select
          labelId="demo-select-medium-label"
          id="demo-select-medium"
          value={players}
          label="Giocatori"
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {giocatori.map((giocatore) => (
            <MenuItem key={giocatore.idGiocatore} value={giocatore.idGiocatore}>{giocatore.nome} {giocatore.cognome}</MenuItem>
          ))}
        </Select>
      </FormControl>}
    </>

  );
}