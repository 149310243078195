import React, { useEffect, useState } from 'react'
import { Titolo } from '../component/Titolo'
import { Button, Container, Table, TableBody, TableCell, TableHead, TableRow, Typography, TableContainer, Paper } from '@mui/material'
import { api } from '../api';
import { ThemeContext } from '@emotion/react';

export default function Classifiche() {

    const [scelta, setScelta] = useState(0);
    const [listaGiocatori, setListaGiocatori] = useState([]);

    useEffect(() => {
        api.getGiocatori.then(data => setListaGiocatori(data))
    }, [])

    const handleClick = (scelta) => {
        setScelta(scelta)
    }

    useEffect(() => {

    }, [scelta])

    return (
        <>
            <Titolo></Titolo>
            <Container sx={{ textAlign: "center" }}>
                <Typography sx={{ textAlign: "center" }} variant='h5'>Guarda le statistiche dei giocatori!</Typography>
                <Typography sx={{ textAlign: "center" }} variant='subtitle1'>Scegli come visualizzare la classifica dei giocatore in base ai goal, le partite o la valutazione</Typography>
                <Container sx={{ marginY: "20px" }}>
                    <Button onClick={() => handleClick(0)} sx={{ fontSize:{xs:"12px",md:"15px"}, marginX: "5px" }} color='secondary' variant='contained'>Vittorie</Button>
                    <Button onClick={() => handleClick(1)} sx={{fontSize:{xs:"12px",md:"15px"},  marginX: "5px" }} color='secondary' variant='contained'>Goal</Button>
                    <Button onClick={() => handleClick(2)} sx={{fontSize:{xs:"12px",md:"15px"},  marginX: "5px" }} color='secondary' variant='contained'>%Goal/Partite</Button>

                </Container>
                <Typography sx={{ marginY: "10px" }}>Classifica in base: {scelta == 0 ? "VITTORIE" : scelta==1? "GOAL": "GOAL/PARTITE"}</Typography>
            </Container>
            <TableContainer sx={{ backgroundColor: "#fff", width: "98%", margin: "auto" }} component={Paper}>
                <Table>

                    <TableHead sx={{ backgroundColor: "#9c27b0" }}>
                        <TableRow >
                            <TableCell sx={{ color: "#fff" }}>
                                Id
                            </TableCell>
                            <TableCell sx={{ color: "#fff" }}>
                                Nome
                            </TableCell>
                            <TableCell sx={{ color: "#fff" }}>
                                Cognome
                            </TableCell>
                            <TableCell sx={{ color: "#fff" }}>
                                Partite
                            </TableCell>
                            {scelta == 0 ? <TableCell sx={{ color: "#fff" }}>
                                %Vittorie
                            </TableCell> : (scelta==1) ?<TableCell sx={{ color: "#fff" }}>
                                Goal
                            </TableCell>:<TableCell  sx={{ color: "#fff" }}>G/P</TableCell>}

                        </TableRow>

                    </TableHead>
                    <TableBody >

                        {
                            (scelta == 0) ? [].concat(listaGiocatori)
                                .filter((giocatore) => giocatore.partiteGiocate != null)
                                .sort((a, b) => (Math.round((parseInt(a.partiteVinte) / parseInt(a.partiteGiocate) * 100))) > (Math.round((parseInt(b.partiteVinte) / parseInt(b.partiteGiocate) * 100))) ? -1 : 1)
                                .map((giocatore, index) => (
                                    index < 3 ? <TableRow sx={{ backgroundColor: "#fd0" }}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{giocatore.nome}</TableCell>
                                        <TableCell>{giocatore.cognome}</TableCell>
                                        <TableCell>{giocatore.partiteGiocate}</TableCell>
                                        <TableCell>{Math.round((parseInt(giocatore.partiteVinte) / parseInt(giocatore.partiteGiocate) * 100))}%</TableCell>

                                    </TableRow> : <TableRow >
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{giocatore.nome}</TableCell>
                                        <TableCell>{giocatore.cognome}</TableCell>
                                        <TableCell>{giocatore.partiteGiocate}</TableCell>
                                        <TableCell>{Math.round((parseInt(giocatore.partiteVinte) / parseInt(giocatore.partiteGiocate) * 100))}%</TableCell>
                                    </TableRow>
                                )) : (scelta == 1) ?
                                [].concat(listaGiocatori)
                                    .filter((giocatore) => giocatore.goalTotali != null)
                                    .sort((a, b) => (parseInt(a.goalTotali) < parseInt(b.goalTotali)) ? 1 : -1)
                                    .map((giocatore, index) => (

                                        index < 3 ? <TableRow sx={{ backgroundColor: "#fd0" }}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{giocatore.nome}</TableCell>
                                            <TableCell>{giocatore.cognome}</TableCell>
                                            <TableCell>{giocatore.partiteGiocate}</TableCell>
                                            <TableCell>{giocatore.goalTotali}</TableCell>

                                        </TableRow> : <TableRow >
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{giocatore.nome}</TableCell>
                                            <TableCell>{giocatore.cognome}</TableCell>
                                            <TableCell>{giocatore.partiteGiocate}</TableCell>
                                            <TableCell>{giocatore.goalTotali}</TableCell>

                                        </TableRow>))
                                : (scelta == 2) ?
                                    [].concat(listaGiocatori)
                                        .filter((giocatore) => giocatore.partiteGiocate != null)
                                        .sort((a, b) => (Math.round((parseFloat(a.goalTotali) / parseFloat(a.partiteGiocate))*100)/100 < Math.round((parseFloat(b.goalTotali) / parseFloat(b.partiteGiocate))*100)/100) ? 1 : -1)
                                        .map((giocatore, index) => (

                                            index < 3 ? <TableRow sx={{ backgroundColor: "#fd0" }}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{giocatore.nome}</TableCell>
                                                <TableCell>{giocatore.cognome}</TableCell>
                                               
                                                <TableCell>{giocatore.partiteGiocate}</TableCell>
                                                <TableCell>{Math.round((parseFloat(giocatore.goalTotali) / parseFloat(giocatore.partiteGiocate))*100)/100}</TableCell>
                                            </TableRow> : <TableRow >
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{giocatore.nome}</TableCell>
                                                <TableCell>{giocatore.cognome}</TableCell>
                                                <TableCell>{giocatore.partiteGiocate}</TableCell>
                                                <TableCell>{Math.round((parseFloat(giocatore.goalTotali) / parseFloat(giocatore.partiteGiocate))*100)/100}</TableCell>

                                            </TableRow>)) : ""
                        }

                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
