import React, { useEffect, useState } from 'react'

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Paper, Typography, IconButton } from '@mui/material';
import DialogPartita from './DialogPartita'
import { api } from '../api';
import { AdapterDayjs, ClearIcon } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers';
import { DeleteForever, DeleteForeverRounded, DeleteOutline, DeleteOutlineOutlined, DeleteOutlineRounded, DeleteOutlineSharp, DeleteSweepOutlined } from '@mui/icons-material';

export default function AggiungiPartitaDialog({ giocatori }) {

    const [squadra1, setSquadra1] = useState([]);
    const [squadra2, setSquadra2] = useState([]);
    const [golGiocatori, setGolGiocatori] = useState([]);
    const [gol1, setGol1] = useState(0);
    const [gol2, setGol2] = useState(0);
    const [data, setData] = useState();
    const [childData, setChildData] = useState();
    const [hidden, setHidden] = useState(true)
    const [hidden2, setHidden2] = useState(true)
    const [boolGol, setBoolGol] = useState(false)
    useEffect(() => {
        api.getGiocatori.then(data => setChildData(data))

    }, [])

    //console.log(childData)
    const [open, setOpen] = useState(false);
    // console.log(giocatori)

    const handleGiocatori = (idGiocatore) => {
        const giocatoriRimasti = childData.filter((giocatore) => idGiocatore !== giocatore.idGiocatore);
        //console.log(giocatoriRimasti)
        setChildData(giocatoriRimasti);

    }

    const handleSquadra1 = (idGiocatore) => {
        let giocatore = childData.filter((giocatore) => idGiocatore == giocatore.idGiocatore, false)
        setSquadra1(squadra1 => squadra1.concat(giocatore))
        if (squadra1.length >= 4) {
            setHidden(false);
        }
    }

    const handleSquadra2 = (idGiocatore) => {
        let giocatore = childData.filter((giocatore) => idGiocatore == giocatore.idGiocatore, false)
        setSquadra2(squadra2 => squadra2.concat(giocatore))
        if (squadra2.length >= 4) {
            setHidden2(false);
        }
    }
    const handleGol1 = (event) => {

        golGiocatori.map((giocatore1) => {
            if (squadra1.filter((giocatore) => giocatore1.giocatore.idGiocatore === giocatore.idGiocatore)) {
                setGol1(prev => prev + giocatore1.giocatore.gol)
            }
        })

    }
    const handleGol2 = (event) => {

        golGiocatori.map((giocatore2) => {
            if (squadra2.filter((giocatore) => giocatore2.giocatore.idGiocatore === giocatore.idGiocatore)) {
                setGol2(prev => prev + giocatore2.giocatore.gol)
            }
        })
    }
    const handleDate = (event) => {
        let date1 = new Date(event.$d).toLocaleDateString('en-GB')
        setData(date1);

    }

    const handleGolGiocatori = (event, giocatore1) => {
        let gol = 0;
        if (event.target.value == null || event.target.value == "") { gol = 0 } else { gol = parseInt(event.target.value) }
        let index = -1
        let cont = 0;
        golGiocatori.forEach(giocatore => {

            if (giocatore.giocatore.idGiocatore === giocatore1.idGiocatore) {
                index = cont;
            }
            cont++;
        });
        console.log(index)
        if (index > -1) {
            let newArr = [...golGiocatori]; // copying the old datas array
            // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
            newArr[index] = { giocatore: giocatore1, gol: gol }; // replace e.target.value with whatever you want to change it to

            setGolGiocatori(newArr);
        } else {
            setGolGiocatori(golGiocatori.concat({ giocatore: giocatore1, gol: gol }))
        }
        // console.log(golGiocatori.length);

    }


    const removeGiocatoreFromSquadra1 = (idGiocatore) => {
        const squadraRimasta = squadra1.filter((giocatore) => idGiocatore !== giocatore.idGiocatore);
        setGolGiocatori(golGiocatori.filter(golGiocatore => golGiocatore.giocatore.idGiocatore !== idGiocatore));
        setChildData(childData.concat(squadra1.filter((giocatore) => idGiocatore == giocatore.idGiocatore)))
        setSquadra1(squadraRimasta)
        setHidden(true)
    }
    const removeGiocatoreFromSquadra2 = (idGiocatore) => {
        const squadraRimasta = squadra2.filter((giocatore) => idGiocatore !== giocatore.idGiocatore);
        setGolGiocatori(golGiocatori.filter(golGiocatore => golGiocatore.giocatore.idGiocatore !== idGiocatore));
        setChildData(childData.concat(squadra2.filter((giocatore) => idGiocatore == giocatore.idGiocatore)))
        setSquadra2(squadraRimasta)
        setHidden2(true)
    }
    useEffect(() => {


        setGol1(0)
        setGol2(0)

        golGiocatori.forEach(giocatoreGol => {
            let prova = squadra1.filter((giocatore) => giocatoreGol.giocatore.idGiocatore === giocatore.idGiocatore);

            if (prova.length == 1) {
                setGol1(prev => prev + giocatoreGol.gol)

            } else {
                setGol2(prev => prev + giocatoreGol.gol)
            }
        });
        if (golGiocatori.length == 10) {
            setBoolGol(true);
        }
    }, [golGiocatori])
    const handleSendSquadre = () => {
        setOpen(false);
        if (squadra1.length != 5 || squadra2.length != 5) {
            alert("Inserire tutti i giocatore della partita")
        } else if (data == "" || data == null) {
            alert("Inserire la data")
        } else {
            api.sendPartita(squadra1, squadra2, gol1, gol2, data, golGiocatori);
            handleClose();
        }

    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSquadra1([]);
        setSquadra2([]);
        setGol1(0);
        setGol2(0);
        setHidden(true);
        setHidden2(true);
        setGolGiocatori([]);
        api.getGiocatori.then(data => setChildData(data))
    };
    const handleClose1 = () => {
        setOpen(false)
    }
    const valore = (giocatore) => {
        if (golGiocatori.filter((giocatore1) => giocatore1.giocatore.idGiocatore === giocatore.idGiocatore).length != 0) { return golGiocatori.filter((giocatore1) => giocatore1.giocatore.idGiocatore === giocatore.idGiocatore)[0].gol } else { return "" }
    }
    return (
        <div>
            <Button variant="contained" color='secondary' onClick={handleClickOpen}>
                AGGIUNGI UNA PARTITA
            </Button>
            <Dialog open={open} onClose={handleClose1}>
                <Box sx={{ display: "flex", float: "left", alignItems: "center" }}>
                    <Typography variant='h4' sx={{ marginTop: "10px", marginLeft: "10px", align: "center", width: "60%", display: "flex" }} >Partita</Typography>
                    <DatePicker format='DD-MM-YYYY' onChange={handleDate} sx={{ width: "30%", display: "flex", marginTop: "10px" }} />
                </Box>
                <DialogContent>
                    <DialogContentText>
                        Inserisci le due squadre, la data, il risultato e i marcatori della partita e clicca conferma per salvare il risultato.
                    </DialogContentText>
                    <Box sx={{ display: { xs: 'block', md: 'flex', lg: 'flex' } }} >
                        <Paper sx={{ padding: "10px", marginRight: "10px", width: { xs: "100%", md: "50%" } }}>
                            <Typography variant='button' sx={{ fontWeight: "bold" }}>Squadra 1</Typography>

                            <Box sx={{ width: '100%' }}>
                                {

                                    squadra1.map((giocatore) => (
                                        <div key={giocatore.idGiocatore} style={{ display: "flex" }}>
                                            <Typography sx={{ width: "55%" }}>{giocatore.nome}</Typography>
                                            <TextField onChange={(e) => handleGolGiocatori(e, giocatore)}
                                                sx={{ marginTop: "10px", width: "35%" }}
                                                id="outlined-number"
                                                label="Number"
                                                type="number"
                                                size='small'
                                                value={
                                                    valore(giocatore)
                                                }
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            <IconButton aria-label="delete" color='error'>
                                                <ClearIcon onClick={() => removeGiocatoreFromSquadra1(giocatore.idGiocatore)} />
                                            </IconButton>
                                        </div>
                                    ))
                                }
                            </Box>

                            <DialogPartita callback={handleSquadra1} handleGiocatori={handleGiocatori} giocatori={childData} bool={hidden}></DialogPartita>


                        </Paper>
                        <Paper sx={{ padding: "10px", width: { xs: "100%", md: "50%" } }}>
                            <Typography variant='button' sx={{ fontWeight: "bold" }}>Squadra 2</Typography>
                            {
                                squadra2.map((giocatore) => (
                                    <div key={giocatore.idGiocatore} style={{ display: "flex" }}>
                                        <Typography sx={{ width: "55%" }}>{giocatore.nome}</Typography>
                                        <TextField onChange={(e) => handleGolGiocatori(e, giocatore)}
                                            sx={{ marginTop: "10px", width: "35%" }}
                                            id="outlined-number"
                                            label="Number"
                                            type="number"
                                            size='small'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={
                                                valore(giocatore)
                                            }
                                        />
                                        <IconButton aria-label="delete" color='error'>
                                            <ClearIcon onClick={() => removeGiocatoreFromSquadra2(giocatore.idGiocatore)} />
                                        </IconButton>
                                    </div>
                                ))
                            }
                            <DialogPartita callback={handleSquadra2} handleGiocatori={handleGiocatori} giocatori={childData} bool={hidden2}></DialogPartita>

                        </Paper>



                    </Box>
                    <Paper>
                        {!hidden && !hidden2 && boolGol && <TextField onChange={handleGol1}
                            sx={{ marginTop: "10px", width: "50%" }}
                            id="outlined-number"
                            label="Squadra1"
                            type="number"
                            value={gol1}
                            InputLabelProps={{
                                shrink: true,

                                readOnly: true,

                            }}
                        />}{!hidden && !hidden2 && boolGol &&
                            <TextField disabled onChange={handleGol2}
                                sx={{ marginTop: "10px", width: "50%" }}
                                id="outlined-number"
                                label="Squadra2"
                                type="number"
                                value={gol2}
                                InputLabelProps={{
                                    readOnly: true,
                                    shrink: true,
                                }}
                            />}
                    </Paper>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancella</Button>
                    <Button onClick={handleSendSquadre}>Conferma</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

