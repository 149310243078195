import React, { useEffect, useState } from 'react'
import { Box, Button, Fab, Grid, Paper, Typography, CircularProgress, Container } from '@mui/material'
import { useParams } from 'react-router-dom';
import { api } from '../api';
import { Titolo } from '../component/Titolo'
import { Link } from 'react-router-dom';
import { CardMedia } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function SchedaGiocatore() {

  const params = useParams();
  const navigate = useNavigate();
  const [giocatori, setGiocatori] = useState();
  const [giocatore, setGiocatore] = useState();
  const [immagine, setImmagine] = useState(null);
  const [video, setVideo] = useState(null);
  const [voto,setVoto]=useState(0);

  const fetchData = async () => {
    api.getGiocatore(params.id).then(data => setGiocatore(data))
    api.getGiocatori.then(data => setGiocatori(data))
    api.getMediaVoto(params.id).then(data=>setVoto(data))
  }

  useEffect(() => {
    fetchData();
  }, [])

  useEffect(() => {
    // Carica l'immagine in base al nome specificato

    try {
      if (giocatore) {
        let img = (giocatore.nome + giocatore.cognome + "1.png").toLowerCase()
        //  console.log(img)
        const imageFile = require(`../assets/img/${img}`);
        setImmagine(imageFile);
      }
    } catch (error) {
      /*   console.error(giocatore);*/
    }
    try {
      if (giocatore) {
        let vid = (giocatore.nome + giocatore.cognome + "1.mp4").toLowerCase()
        //  console.log(img)
        const vidFile = require(`../assets/video/${vid}`);
        setVideo(vidFile);
      }
    } catch (error) {
      /*   console.error(giocatore);*/
    }
  }, [giocatore]);

  const goToPreviousPage = () => {
    if (params.id > 1) {
      navigate(`/SchedaGiocatore/${parseInt(params.id) - 1}`);
      navigate(0)
    }
  };

  const goToNextPage = () => {
    navigate(`/SchedaGiocatore/${parseInt(params.id) + 1}`);
    navigate(0)
  };

  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50

  const onTouchStart = (e) => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe) {
      if (giocatori) {
        if (params.id < (giocatori.length)) {
          navigate(`/SchedaGiocatore/${parseInt(params.id) + 1}`);
          navigate(0)
        }
      }

    } else if (isRightSwipe) {
      if (params.id > 1) {
        navigate(`/SchedaGiocatore/${parseInt(params.id) - 1}`);
        navigate(0)
      }

    }
    // add your conditional logic here
  }


  return (
    <>
      <Titolo />
      <div onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
        {(giocatore) ?
          <Box>
            <Paper elevation={3} sx={{ backgroundColor: "#333333", color: "white", padding: "10px", width: "95%", minHeight: { md: "450px" }, margin: "auto" }} >
              <Grid spacing={2} container>
                <Grid item xs={5} md={3}>
                  {immagine ? <Box
                    sx={{
                      content: {
                        xs: `url(${immagine})`, //img src from xs up to md
                        width: "100%"
                      }
                    }}></Box> : <Box sx={{ textAlign: "center", width: "100%" }}>Immagine non presente!</Box>}
                </Grid>
                <Grid item xs={7} md={7}>
                  {giocatore ?
                    <Box>
                      <Typography variant='h5' sx={{ marginBottom: "20px" }}>{giocatore.nome} {giocatore.cognome}</Typography>

                      <Typography variant='subtitle1'>Partite: {giocatore.partiteGiocate}</Typography>
                      <Typography variant='subtitle1'>Vittorie: {giocatore.partiteVinte}</Typography>
                      <Typography variant='subtitle1'>Pareggi: {giocatore.partitePareggiate}</Typography>
                      <Typography variant='subtitle1'>Sconfitte: {parseInt(giocatore.partiteGiocate) -( parseInt(giocatore.partiteVinte) + parseInt(giocatore.partitePareggiate))}</Typography>
                      <Typography variant='subtitle1'>N° Goal: {giocatore.goalTotali}</Typography>
                      <Typography variant='subtitle1'>Media voto: {Math.round(parseFloat(voto.voto) * 10) / 10}</Typography>

                    </Box>

                    : <Box sx={{ display: "flex", justifyContent: "center" }}><CircularProgress color="secondary" /></Box>
                  }

                </Grid>
              </Grid>
            </Paper>
            <Paper square elevation={3} sx={{ textAlign: "center", backgroundColor: "#333333", color: "white", paddingBottom: "10px", width: "100%", margin: "auto", marginTop: { xs: "15%", md: "5%" } }}>
              <Typography sx={{ fontWeight: "bold", paddingY: "10px" }} variant='subtitle2'>Highlights</Typography>
              <CardMedia sx={{ width: "90%", display: { xs: "block", sm: "block", md: "block" }, margin: "auto" }}
                component="video"
                autoPlay
                controls src={video}
              />
            </Paper>
          </Box> : <Box sx={{ display: "flex", justifyContent: "center" }}><CircularProgress color="secondary" /></Box>}

        <Box sx={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
          {((parseInt(params.id) - 1) != 0) ? <Fab sx={{ marginRight: "20px" }} size="small" color="secondary" aria-label="add" onClick={goToPreviousPage}>{'<'}</Fab >
            : ""}
          {giocatori ? ((parseInt(params.id) + 1) <= giocatori.length) ? <Fab sx={{ marginRight: "20px" }} size="small" color="secondary" aria-label="add" onClick={goToNextPage}>{'>'}</Fab > : "" : ""}

        </Box>
      </div>
    </>

  )
}
