const localhost = 'https://calcettouni.altervista.org/backend/';    //URL PRODUCTION
const localhost1 = 'http://localhost:8080/calcetto/sito_calcetto/backend/';

export const api = {
    
    getGiocatori: fetch(localhost + "?SchedaGiocatori")
        .then((response) => response.json()),
    getGiocatore: (id) => {
        return fetch(localhost + "?giocatore=" + id)
            .then((response) => response.json())
    },
    getMediaVoto: (id) => {
        return fetch(localhost + "?voto=" + id)
            .then((response) => response.json())
    },
    getPartite: fetch(localhost + "?Partite")
        .then((response) => response.json()),
    getGiocatoriInPartite: fetch(localhost + "?giocatoriInPartite")
        .then((response) => response.json()),
    getVotiPartita:(idPartita)=>{
        return fetch(localhost + "?idPartita=" + idPartita)
            .then((response) => response.json())
    },
    sendPartita: (squadra1, squadra2, gol1, gol2, data, golGiocatori) => {
        fetch(localhost, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify({ squadra1: squadra1, squadra2: squadra2, gol1: gol1, gol2: gol2, data: data, golGiocatori: golGiocatori })
        }).then(response => response)
            .then(response => console.log(response))
    },
    sendVoti: (voti,pw) => {
        fetch(localhost, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify({ voti:voti, pw:pw })
        }).then(response => response)
            .then(response => console.log(response))
    }

}
