import React from "react";
import { useEffect, useState } from 'react';
import { Titolo } from "../component/Titolo";
import { Box, Divider, Typography } from "@mui/material";
import background from "../assets/img_sfondo.png"
import background1 from "../assets/img_sfondo1.png"
import backgroundSmall from "../assets/img_sfondo_vert.png"
import backgroundSmall1 from "../assets/img_sfondo_vert1.png"
import { Zoom } from "@mui/material";

export default function Home({ giocatori }) {

  const imagesSM = [backgroundSmall, backgroundSmall1]
  const images = [background, background1]

  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentIndex == images.length - 1) {
        setCurrentIndex(0);
      }
      else {
        setCurrentIndex(currentIndex + 1);
      }
    }, 5000)
    return () => clearInterval(intervalId);
  }, [currentIndex])


  return (
    <>

      <Titolo />

      <Box sx={{display:"block", textAlign:"center", marginBottom:"5%"}}>
        <Typography variant="h6">Benvenuto nel calcetto UNIBG!</Typography>
        <Typography  variant="subtitle2">Qui puoi trovare le statistiche dei giocatori, i risultati delle partite e inserire nuove partite!</Typography>
      </Box>

      <Box
        component="img"
        sx={{
          width: "100%",


          content: {
            xs: `url(${imagesSM[1]})`, //img src from xs up to md
            sm: `url(${images[currentIndex]})`,  //img src from md and up
          }
        }}
      >
      </Box>
    </>
  );
}