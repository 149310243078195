import { useEffect, useState } from 'react';
import { api } from './api.js';
import Home from './pages/Home';
import Schede from './pages/Schede';
import { Route, Routes } from 'react-router-dom';
import Partite from './pages/Partite'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import SchedaGiocatore from './pages/SchedaGiocatore';
import Classifiche from './pages/Classifiche.js';
import Pagelle  from './pages/Pagelle.js';

function App() {
 
  const [giocatori, setGiocatori] = useState([]);

  useEffect(() => {
    api.getGiocatori.then(data => setGiocatori(data))
   
  }, [])

  return (
    <div className="App">
       <LocalizationProvider dateAdapter={AdapterDayjs}>
      {
    
      <Routes>
        <Route path="*" exact element={<Home />} />
        <Route path="Schede Giocatori" element={<Schede giocatori={giocatori} />} />
        <Route path="Partite" element={<Partite />} />
        <Route path="SchedaGiocatore/:id" element={<SchedaGiocatore/>}/>
        <Route path="Statistiche" element={<Classifiche/>}/>
        <Route path="Pagelle" element={<Pagelle/>}/>

      </Routes>
}
</LocalizationProvider>
    </div>
  )

}

export default App;
