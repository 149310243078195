import { Table, TableCell, TableHead, TableRow, Typography, Button, Box, TableBody, Dialog } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Titolo } from '../component/Titolo'
import AggiungiPartitaDialog from '../component/AggiungiPartitaDialog'
import { api } from '../api'
import LinkIcon from '@mui/icons-material/Link';
export default function Partite() {
    const [open, setOpen] = useState(false)
    const [partite, setPartite] = useState([]);
    const [giocatori, setGiocatori] = useState([])
    const [contOfDialog, setContOfDialog] = useState();
    useEffect(() => {
        api.getPartite.then(data => setPartite(data))
        api.getGiocatoriInPartite.then(data => setGiocatori(data))
    }, []
    )
    const handleButtonShowSquadra = (cont1) => {
        setOpen(true);
        setContOfDialog(cont1);

    }
    const handleClose = () => {
        setOpen(false);
        setContOfDialog(-1)
    }
    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <Table>
                    <TableHead>
                        <TableRow >
                            <TableCell >Squadra A</TableCell>
                            <TableCell >Gol A</TableCell>
                            <TableCell >Squadra B</TableCell>
                            <TableCell >Gol B</TableCell>
                        </TableRow>

                    </TableHead>

                    <TableBody>
                        <TableRow>

                            <TableCell sx={{
                                display: { xs: 'table-cell', sm: 'none' }
                            }}
                            >{giocatori.map((item1, i) => { if (i < (contOfDialog * 10) + 5 && i >= (contOfDialog * 10)) return (<p style={{ height: "40px" }}>{item1.nome} <br></br> {item1.cognome}</p>) })}</TableCell>
                            <TableCell sx={{
                                display: { xs: 'table-cell', sm: 'none' }
                            }}
                            >{giocatori.map((item1, i) => { if (i < (contOfDialog * 10) + 5 && i >= (contOfDialog * 10)) return (<p style={{ height: "40px" }}>{item1.golGiocatore}<br></br></p>) })}
                            </TableCell>
                            <TableCell sx={{
                                display: { xs: 'table-cell', sm: 'none' }
                            }}>{giocatori.map((item1, i) => { if (i >= (contOfDialog * 10) + 5 && i < (contOfDialog + 1) * 10) return (<p style={{ height: "40px" }}>{item1.nome}<br></br> {item1.cognome}</p>) })}</TableCell>
                            <TableCell sx={{
                                display: { xs: 'table-cell', sm: 'none' }
                            }}
                            >{giocatori.map((item1, i) => { if (i >= (contOfDialog * 10) + 5 && i < (contOfDialog + 1) * 10) return (<p style={{ height: "40px" }}>{item1.golGiocatore}<br></br></p>) })}
                            </TableCell>
                        </TableRow>
                        
                </TableBody>

            </Table>
        </Dialog >
            <Titolo />
            <Table>
                <TableHead>
                    <TableRow >
                        <TableCell>N</TableCell>
                        <TableCell sx={{
                            display: { xs: 'none', sm: 'table-cell' }
                        }}>Squadra A</TableCell>
                        <TableCell sx={{
                            display: { xs: 'none', sm: 'table-cell' }
                        }}>Squadra B</TableCell>
                        <TableCell>Risultato</TableCell>

                        <TableCell>Data</TableCell>
                        <TableCell sx={{
                            display: { xs: 'table-cell', sm: 'none' }
                        }}>Vedi squadre</TableCell>
                        <TableCell>Video</TableCell>
                    </TableRow>

                </TableHead>

                <TableBody>
                    {partite.map((item, cont, arr) =>
                    {
                        if(contOfDialog==cont){
                            return ( (
                                <TableRow sx={{backgroundColor:"lightGreen"}}>
                                    <TableCell>{cont + 1}</TableCell>
        
                                    <TableCell sx={{
                                        display: { xs: 'none', sm: 'table-cell' }
                                    }}
                                    >{giocatori.map((item1, i) => { if (i < (cont * 10) + 5 && i >= (cont * 10)) return (<p>{item1.nome} {item1.cognome} - {item1.golGiocatore}</p>) })}</TableCell>
                                    <TableCell sx={{
                                        display: { xs: 'none', sm: 'table-cell' }
                                    }}>{giocatori.map((item1, i) => { if (i >= (cont * 10) + 5 && i < (cont + 1) * 10) return (<p>{item1.nome} {item1.cognome} - {item1.golGiocatore}</p>) })}</TableCell>
        
        
                                    <TableCell>{item.golSquadra1} - {item.golSquadra2}</TableCell>
                                    <TableCell>{item.data}</TableCell>
                                    <TableCell sx={{
                                        display: { xs: 'table-cell', sm: 'none' }
                                    }}><Button variant='contained' color='success' onClick={() => handleButtonShowSquadra(cont)}>Vedi</Button></TableCell>
                                    <TableCell><Button onClick={()=>window.location.href(item.link)}><LinkIcon></LinkIcon></Button></TableCell>
                                </TableRow>
        
        
        
                            ))
                        }else{
                            return( (
                                <TableRow>
                                    <TableCell>{cont + 1}</TableCell>
        
                                    <TableCell sx={{
                                        display: { xs: 'none', sm: 'table-cell' }
                                    }}
                                    >{giocatori.map((item1, i) => { if (i < (cont * 10) + 5 && i >= (cont * 10)) return (<p>{item1.nome} {item1.cognome} | Goal:{item1.golGiocatore}</p>) })}</TableCell>
                                    <TableCell sx={{
                                        display: { xs: 'none', sm: 'table-cell' }
                                    }}>{giocatori.map((item1, i) => { if (i >= (cont * 10) + 5 && i < (cont + 1) * 10) return (<p>{item1.nome} {item1.cognome} | Goal:{item1.golGiocatore}</p>) })}</TableCell>
        
        
                                    <TableCell>{item.golSquadra1} - {item.golSquadra2}</TableCell>
                                    <TableCell>{item.data}</TableCell>
                                    <TableCell sx={{
                                        display: { xs: 'table-cell', sm: 'none' }
                                    }}><Button variant='contained' color='success' onClick={() => handleButtonShowSquadra(cont)}>Vedi</Button></TableCell>
                                <TableCell><Button><LinkIcon></LinkIcon></Button></TableCell>
                                </TableRow>
        
        
        
                            ))
                        }
                    }
                   )}
                </TableBody>

            </Table>
            <br></br>
            <Box sx={{textAlign:"center"}}>
                <AggiungiPartitaDialog giocatori={giocatori} />
            </Box>

        </>

    )
}
