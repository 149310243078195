import React, { useState, useEffect } from 'react'
import { Titolo } from '../component/Titolo'
import { api } from '../api';
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Dialog, TextField, DialogContent, DialogActions, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
export default function Pagelle() {
    const navigate = useNavigate();
    const [partite, setPartite] = useState();
    const [giocatori, setGiocatori] = useState();
    const [partita, setPartita] = useState();
    const [voti, setVoti] = useState([]);
    const [votiPartita, setVotiPartita] = useState();
    const [open, setOpen] = useState(false);
    const [openPw, setOpenPw] = useState(false);
    const [openVoti, setOpenVoti] = useState(false);
    const [pw, setPw] = useState('');
    const fetchData = async () => {
        api.getPartite.then(data => setPartite(data))
        api.getGiocatoriInPartite.then(data => setGiocatori(data))
    }

    useEffect(() => {
        fetchData();
    }, [])

    const handleOpen = (id) => {
        setPartita(id)
        setOpen(true);
    }

    const handleVoti = (e, giocatore) => {
        //     console.log(giocatore)
        let index = -1
        let cont = 0;
        const object = { idGiocatore: giocatore.idGiocatore, idPartita: giocatore.idPartita, voto: e.target.value };
        voti.forEach(element => {

            if (element.idGiocatore == giocatore.idGiocatore) {

                index = cont;
            }
            cont++;
        });

        if (index > -1) {
            let newArr = [...voti]; // copying the old datas array
            // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
            newArr[index] = object; // replace e.target.value with whatever you want to change it to

            setVoti(newArr);
        } else {
            setVoti(voti.concat(object))
        }
        console.log(voti)
    }

    const handleClose = () => {
        setOpen(false);
    }
    const handlePw = () => {
        setOpenPw(true);

    }
    const handleClosePw = () => {
        setOpenPw(false)
    }

    const handleOpenVotiPartita = (id) => {
        api.getVotiPartita(id).then(data => setVotiPartita(data))
        setOpenVoti(true);
    }

    const handleSendVoti = () => {
        if (voti.length == 10) {
            api.sendVoti(voti, pw)

            handleClosePw()
            handleClose()
            navigate(0)
        }


    }
    return (
        <>
            <Titolo />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>N°</TableCell>
                        <TableCell>Risultato</TableCell>
                        <TableCell>Data</TableCell>
                        <TableCell>Vedi Voti</TableCell>
                    </TableRow>
                </TableHead>
                {partite ? <TableBody>
                    {
                        partite.map((item, cont) => {
                            let bool = false;
                            if (giocatori)
                                giocatori.map((giocatore) => {
                                    if (giocatore.idPartita == item.idPartita) {
                                        if (giocatore.voto != null) {
                                            bool = true
                                        }
                                    }
                                })
                            //  console.log(bool)

                            return (<TableRow>
                                <TableCell>{cont + 1}</TableCell>
                                <TableCell>{item.golSquadra1}-{item.golSquadra2}</TableCell>
                                <TableCell>{item.data}</TableCell>
                                <TableCell>{bool ? <Button variant='contained' onClick={() => handleOpenVotiPartita(item.idPartita)} color='success'>Pagelle</Button> : <Button variant='contained' onClick={() => handleOpen(item.idPartita)} color='warning'>Aggiungi Voti</Button>}</TableCell>
                            </TableRow>
                            )


                        })
                    }
                </TableBody> : ""}
            </Table>


            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nome</TableCell>
                                <TableCell>Cognome</TableCell>
                                <TableCell>Voto</TableCell>

                            </TableRow>
                        </TableHead>
                        {giocatori ? giocatori.map((giocatore) => {
                            if (giocatore.idPartita == partita) {
                                return (
                                    <TableRow>

                                        <TableCell>{giocatore.nome}</TableCell>
                                        <TableCell>{giocatore.cognome}</TableCell>
                                        <TableCell><TextField onChange={(e) => handleVoti(e, giocatore)} type="number"></TextField></TableCell>

                                    </TableRow>
                                )
                            }
                        }) : ""}
                    </Table>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>Cancella</Button>
                    <Button onClick={handlePw}>Conferma</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openPw} onClose={() => setOpenPw(false)}>
                <DialogContent>
                    <Typography>Inserisci il codice per inviare i voti</Typography>
                    <TextField onChange={(e) => (setPw(e.target.value))} type='text'></TextField>
                </DialogContent>
                <DialogActions>

                    <Button onClick={handleSendVoti}>Conferma</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openVoti} onClose={() => setOpenVoti(false)}>
                <DialogContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                    Nome
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                    Cognome
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                    Voto
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {votiPartita ? [].concat(votiPartita)
                                .sort((a, b) => (a.voto > b.voto) ? -1 : 1)
                                .map((item, i) => {
                                    return (
                                        i == 0 ?
                                            <TableRow sx={{backgroundColor:"#fd0"}}>
                                                <TableCell>{item.nome}</TableCell>
                                                <TableCell>{item.cognome}</TableCell>
                                                <TableCell>{item.voto} </TableCell>
                                            </TableRow>
                                            : <TableRow>
                                                <TableCell>{item.nome}</TableCell>
                                                <TableCell>{item.cognome}</TableCell>
                                                <TableCell>{item.voto}</TableCell>
                                            </TableRow>
                                    )
                                }) : ""}
                        </TableBody>
                    </Table>
                </DialogContent>

            </Dialog>
        </>
    )
}
