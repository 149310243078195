import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Container, TextField, Typography } from '@mui/material'
import { Titolo } from '../component/Titolo.js';
import Autocomplete from '@mui/material/Autocomplete';
import { PaperGiocatore } from '../component/PaperGiocatore';
import { api } from '../api.js';

export default function Schede({ giocatori }) {

  const [showGiocatori, setShowGiocatori] = useState()

  const fetchData = async () => {
    api.getGiocatori.then(data => setShowGiocatori(data))
  }

  useEffect(() => {
    fetchData();
  }, [])

  const handleChange = (event) => {
   // console.log(event.target.value)
    if (event.type == 'change')
      setShowGiocatori(giocatori.filter((giocatore) => (giocatore.nome.toLowerCase().includes(event.target.value.toLocaleLowerCase())||giocatore.cognome.toLowerCase().includes(event.target.value.toLocaleLowerCase()))))

  }

  return (
    <>
      <Titolo />
      <Container sx={{ display: "flex", textAlign: "center", justifyContent: "center" }}>

        <TextField
          onChange={handleChange}
          placeholder='Cerca giocatore...'
          color='secondary'
          
          id="combo-box-demo"
       
        />
      </Container>
      {showGiocatori ?
        <Box sx={{ float: "left", width: "100%" }}>
          {
            
            showGiocatori.map((item) => (
              <PaperGiocatore key={item.id} giocatore={item} />
            ))
            
          }
        </Box> : <Box><CircularProgress/></Box>
      }
  
{/*console.log(showGiocatori)*/}
    </>

  )
}
