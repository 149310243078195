import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Link} from "react-router-dom"

export function PaperGiocatore({giocatore}) {
    
    return (
        <>
       
        
        <Card variant="outlined" sx={{backgroundColor:"#222222", color:"white", width:{lg:"45%",xs:"95%",sm:"40%"}, float:"left" ,margin:"10px", padding:"10px",}}>
            <CardContent>
                <Typography variant="h5" component="div">
                    Scheda Giocatore
                </Typography>
                <Typography  variant="body1">
                   {giocatore.idGiocatore} : {giocatore.nome}  {giocatore.cognome}
                </Typography>

            </CardContent>
            <CardActions>
                <Button  component={Link} to={"/SchedaGiocatore/"+giocatore.idGiocatore} color='secondary' size="small">Vedi Scheda</Button>
            </CardActions>
        </Card>
        </>
    );
}